<!-- eslint-disable vue/valid-v-model -->
<template>
  <div class="vl-parent">
    <loading
      :active="true"
      :color="color"
      :is-full-page="true"
      loader="bars"
      :width="100"
      :height="250"
    />
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// #666666
export default {
  components: {
    Loading,
  },
  props: {
    color: {
      type: String,
      default() {
        return ''
      },
    },
    fullPage: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  data() {
    return {
      isLoading: true,
    }
  },
}
</script>
